export const Types = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT: 'LOGOUT',
    SIGNUP: 'SIGNUP',
    SIGNUP_ERROR: 'SIGNUP_ERROR',
    ADD_EVENT: 'ADD_EVENT',
    EDIT_BLOCK: 'EDIT_BLOCK',
    DELETE_BLOCK: 'DELETE_BLOCK',
    CLEAR_BLOCKS: 'CLEAR_BLOCKS'
}